<script setup lang='ts'>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    link: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: 'i-heroicons-share'
    },
})
const social_links = [
    'facebook', 'x', 'whatsapp', 'threads', 'linkedin', 'telegram', 'skype', 'email'
]
const isOpen = ref(false)
const baseUrl = useRuntimeConfig().public.baseUrl
const link = computed(() => {
    if (!props.link.includes('http')) {
        return `${baseUrl}${props.link}`
    }
    return props.link
})
</script>

<template>
    <div>
        <UButton :label="label" :icon="icon" size="sm" variant="solid" color="gray" @click="() => isOpen = true" />

        <UModal v-model="isOpen">
            <UCard>
                <template #header>
                    <div class="flex items-center justify-between">
                        <p class="text-lg font-semibold">
                            Share - {{ title }}
                        </p>
                        <UButton color="gray" icon="i-heroicons-x-mark" @click="() => isOpen = false" />
                    </div>
                </template>
                <div class="flex gap-5 flex-wrap text-center">
                    <template v-for="(value, ind) of social_links" :key="ind">
                        <SocialShare styled :title="title" :network="value" :url="link" :label="true"
                            class="text-white" />
                    </template>
                </div>
            </UCard>
        </UModal>
    </div>

</template>